
@import "normalize.scss";


@import "variables.scss";

@import "bootstrap/mixins/breakpoints.scss";
@import "bootstrap/bootstrap-grid.scss";
@import "bootstrap/_modal.scss";

@import "typography.scss";
@import "components/slick.scss";
@import "components/slick-theme.scss";
@import "common.scss";
@import "blocks/intro.scss";

@import "blocks/ul.scss";
@import "blocks/btn.scss";
@import "blocks/link.scss";

@import "base/header.scss";
@import "base/footer.scss";
@import "base/black-mode-test.scss";


// new
@import "blocks/ul--check.scss";
@import "blocks/editor.scss";
@import "blocks/voice.scss";
@import "blocks/eye.scss";
@import "blocks/work.scss";
@import "blocks/design.scss";
@import "blocks/online.scss";
@import "blocks/garant.scss";
@import "blocks/projects.scss";
@import "blocks/sertif.scss";



