.header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #ffffff;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);

  @include media-down-lg {
    position: fixed;
    width: 100%;
  }


  &__bottom {
    color: #ffffff;
    background: #003b8c;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo-wrap {
    max-width: 250px;
    width: 13%;
    height: 100px;
    flex: 0 0 auto;
    border-bottom-right-radius: 80px;
    box-shadow: 2px -17px 15px rgba(0, 0, 0, 0.20);
    padding: 5px 10px;
    background: #ffffff;
    margin-bottom: -30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-down-xxl {
      width: 220px;
      height: 113px;
    }

    @include media-down-xl {
      width: 180px;
    }

    img {
      width: 180px;
      height: auto;

      @include media-down-lg {
        width: 150px;
      }
    }
  }

  &__menu-wrap {
    flex: 1;
  }

  &__menu {
    display: flex;
    gap: 2px 28px;
    list-style-type: none;
    padding: 0;
    margin: 0;

    @include media-down-xxl {
      flex-direction: column;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    li {
      padding: 0;
      left: 0;
    }

    a {
      color: #000000;
      font-size: 14px;
      text-decoration: underline;
      font-weight: 500;
      white-space: nowrap;

      @include media-down-xl {
        font-size: 13px;
      }
    }
  }

  &__btns {
    display: flex;
    gap: 10px;
  }

  &__btn-orange {
    background: #FF7A2F;
    overflow: hidden;
    position: relative;
    color: white;
    cursor: pointer;
    height: 46px;
    font-size: 13px;
    line-height: 16px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 24px;
    text-decoration: none;
    display: flex;
    font-weight: 500;
    padding-bottom: 2px;
    white-space: nowrap;

    @include media-down-sm {
      padding-left: 16px;
      padding-right: 16px;
    }

    &:before {
      position: absolute;
      content: '';
      display: inline-block;
      top: -180px;
      left: 0;
      width: 30px;
      height: 100%;
      background-color: #fff;
    }
  }

  &__btn--free {
    text-align: center;
    align-items: center;
    justify-content: center;

    &:before {
      -webkit-animation: shiny-btn1 4s ease-in-out infinite;
      -moz-animation: shiny-btn1 4s ease-in-out infinite;
      -o-animation: shiny-btn1 4s ease-in-out infinite;
      animation: shiny-btn1 4s ease-in-out infinite;
    }
  }

  &__btn--free-bold {
    font-weight: 700;
    color: #FFE3AA;
  }

  &__btn--order {
    display: flex;
    padding-top: 6px;
    gap: 10px;

    &:before {
      -webkit-animation: shiny-btn1 4s ease-in-out infinite 2s;
      -moz-animation: shiny-btn1 4s ease-in-out infinite 2s;
      -o-animation: shiny-btn1 4s ease-in-out infinite 2s;
      animation: shiny-btn1 4s ease-in-out infinite 2s;
    }
  }

  &__btn--order-bold {
    font-weight: 600;
    font-size: 15px;
    white-space: nowrap;
  }

  &__right {
    width: 300px;
    flex: 0 0 auto;
    margin-left: auto;

    @include media-down-xxl {
      width: auto;
      padding-right: 10px;
    }

    * {
      font-size: 13px;
      color: #000000;
      text-decoration: none;
    }
  }

  &__right-phones {
    @include media-down-xxl {
      display: flex;
      flex-direction: column;
    }
  }

  &__bottom-main {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    padding-left: 300px;
    flex: 1;

    @include media-down-xl {
      padding-left: 260px;
      font-size: 13px;
      }
  }

  &__bottom-right {
    display: flex;
    gap: 30px;
    align-items: center;

    @include media-down-xl {
      gap: 18px;
    }
  }

  &__bottom-link {
    text-decoration: underline;
    color: #FFD16C;
    padding-left: 10px;
    font-weight: 500;
  }

  &__bottom-soc {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    gap: 6px;

    li {
      margin: 0;
      padding: 0;
    }

    a {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 0 auto;
      transition: all .3s ease-in-out;

      &:hover {
        opacity: .8;
      }
    }
  }

  &__email-link {
    font-size: 13px;
    color: #ffffff;
    text-decoration: none;
  }

  // MOB

  &__logo-mob {
    width: 38px;
  }

  &__burger {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hidden-on-mob {
    @include media-down-lg {
      display: none;
    }
  }

  &__mob-menu {
    position: fixed;
    top: 53px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
  }

  &__menu-mob {
    gap: 16px;

    li {
      a {
        font-size: 18px;
        white-space: normal;
      }
    }
  }

  &__mob-menu-inner {
    gap: 36px;
    justify-content: space-between;
  }

  &__mob-contacts {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 8px;

    a {
      color: #444444;
    }
  }

  &__btn-mob-lk {
    font-weight: 500;
    color: #000000;
    background: white;
    border: 2px solid $brand-orange;
    border-radius: 24px;
    cursor: pointer;
    text-decoration: none;
    font-size: 13px;
    line-height: 16px;
    white-space: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__mob-lk {
    text-decoration: none;
    color: $brand-orange;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 28px;
      height: 28px;
    }
  }

  &__mob-lk-txt {
    color: #000000;
    text-decoration: underline;
    font-size: 14px;
  }

  &__bottom-soc-mob {
    gap: 16px;

    a {
      background: $brand-blue;
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
  }
}

@-webkit-keyframes shiny-btn1 {
  0% { -webkit-transform: scale(0) rotate(45deg); opacity: 0; }
  2% { -webkit-transform: scale(0) rotate(45deg); opacity: 0.2; }
  3% { -webkit-transform: scale(4) rotate(45deg); opacity: 0.5; }
  30% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
  100% { -webkit-transform: scale(50) rotate(45deg); opacity: 0; }
}

@-moz-keyframes shiny-btn1 {
  0% { -moz-transform: scale(0) rotate(45deg); opacity: 0; }
  2% { -moz-transform: scale(0) rotate(45deg); opacity: 0.2; }
  3% { -moz-transform: scale(4) rotate(45deg); opacity: 0.5; }
  30% { -moz-transform: scale(50) rotate(45deg); opacity: 0; }
  100% { -moz-transform: scale(50) rotate(45deg); opacity: 0; }
}

@-o-keyframes shiny-btn1 {
  0% { -o-transform: scale(0) rotate(45deg); opacity: 0; }
  2% { -o-transform: scale(0) rotate(45deg); opacity: 0.2; }
  3% { -o-transform: scale(4) rotate(45deg); opacity: 0.5; }
  30% { -o-transform: scale(50) rotate(45deg); opacity: 0; }
  100% { -o-transform: scale(50) rotate(45deg); opacity: 0; }
}

@keyframes shiny-btn1 {
  0% { transform: scale(0) rotate(45deg); opacity: 0; }
  2% { transform: scale(0) rotate(45deg); opacity: 0.2; }
  3% { transform: scale(4) rotate(45deg); opacity: 0.5; }
  30% { transform: scale(50) rotate(45deg); opacity: 0; }
  100% { transform: scale(50) rotate(45deg); opacity: 0; }
}