// height
.h-100 {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-width: 440px;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
  color: $text-color;
  overflow-x: hidden;
}

main {
  flex-grow: 1;
  background: #ffffff;
  color: $text-color;

}

.w-100 {
  max-width: 100%;
  height: auto;
  display: block;
}

.block-with-shadow {
  background: $white-color;
  border-radius: 16px;
  box-shadow: 0px 4px 5px 0px rgba($black-color, .25);
}

.block-with-shadow--blue {
  background: url('../images/bg-alert.svg') top right no-repeat $darker-blue;
  background-size: auto 100%;
  border-radius: 16px;
  box-shadow: 0px 4px 5px 0px rgba($black-color, .25);
}

.block-with-shadow--light-blue {
  background: $light-blue;
  border-radius: 16px;
  box-shadow: 0px 4px 5px 0px rgba($black-color, .25);
}

.block--bordered {
  border: 1px solid $white-color;
  background: $darker-blue;
  border-radius: 16px;
  padding: 9px 9px 16px;
}

.block--bordered-blue {
  border: 3px solid $brand-blue;
  border-radius: 16px;
}

.section:not([class*="pb-"]) {
  padding-bottom: 60px;

  @include media-down-lg {
    padding-bottom: 30px;
  }

  /*@include media-down-sm {
    padding-bottom: 20px;
  }*/
}

.section:not([class*="pt-"]) {
  padding-top: 60px;

  @include media-down-lg {
    padding-top: 30px;
  }

/*  @include media-down-sm {
    padding-top: 20px;
  }*/
}

.container-fluid {

/*  min-width: 408px;*/

  @include media-down-lg {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include media-down-sm {
    padding-left: 16px;
    padding-right: 16px;
  }
}

// text alignment

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

// font weight

.text-bold {
  font-weight: 700;
}

.text-semibold {
  font-weight: 500;
}

.text-semibold600 {
  font-weight: 600;
}

// color

.color-light-yellow {
  color: #FFDC83;
}

.modal-backdrop {

  min-width: 100%!important;
  min-height: 100%!important;
}



