.online {

  &__block {
    background: $light-blue;
    border-radius: 24px;
    font-weight: 500;

    &:first-child {
      position: relative;
      z-index: 1;
    }

    @include media-down-lg {
      max-width: 370px;
      font-size: 14px;
      margin-left: auto;
      margin-right: auto;
    }

    &--letter {
      position: relative;
      z-index: 1;
      background-image: url("../images/steps-bg.png") center bottom no-repeat;
    }
  }

  &__col--letter {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 1072px;
      top: -43px;
      height: 455px;
      background: url("../images/plane.svg") center top no-repeat;
      z-index: 0;

      @include media-down-xl {
        width: 923px;
        top: 0;
      }

      @include media-down-lg {
        top: -45px
      }

      @include media-down-md {
        background: url("../images/plane-sm.svg") top left no-repeat;
        width: 600px;
        height: 224px;
        top: -80%;
      }

      @include media-down-sm {
        display: none;
      }
    }
  }

  &__sm {
    max-width: 328px;

    @include media-down-sm {
      max-width: none;
    }
  }

  &__number {
    color: $brand-blue;
    background: white;
    width: 56px;
    height: 56px;
    border: 3px solid $brand-blue;
    border-radius: 50%;
    flex: 0 0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
    position: relative;
    font-size: 28px;
    font-weight: 700;

    @include media-down-lg {
      width: 42px;
      height: 42px;
      font-size: 24px;
      border: 2px solid $brand-blue;

    }

  }

  &__pic {
    overflow: hidden;
    border-radius: 40px;

    @include media-down-xl {
      border-radius: 20px;
    }
  }

  &__pic1 {
    left: 60px;
    margin-right: 40px;
    top: 40px;
    position: relative;

    @include media-down-lg {
      top: 10px;
      left: 20px;
    }
  }

  &__pic2 {
    top: 30px;
    position: relative;

    @include media-down-xl {
      max-width: 220px;
      margin-left: auto;
      margin-right: auto;
    }

    @include media-down-lg {
      top: 10px;
    }
  }

  &__pic3 {
    left: 60px;
    margin-right: 20px;
    top: 40px;
    position: relative;

    @include media-down-lg {
      top: 10px;
      left: 20px;
      margin-right: 25px;
    }
  }

  &__archive {
    z-index: 2;
    position: relative;
    top: 20px;
    border-radius: 20px;
    background: rgb(255,209,108);
    background: linear-gradient(225deg, rgba(255,209,108,1) 0%, rgba(255,125,78,1) 100%);

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      border: 5px solid rgba(255,125,78,.3);
      width: 100%;
      height: 100%;
      border-radius: 22px;

    }
  }

  &__archive-icon {
    width: 90px;
    height: 86px;
    margin-top: -24px;
    margin-left: -20px;
    z-index: 2;
    margin-right: 13px;
    position: relative;
    flex: 0 0 auto;

    @include media-down-xl {
      width: 70px;
      height: 70px;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50px;
      animation: pulseButton 2s ease infinite;
      animation-delay: 1.5s;
    }
    &:after {
      opacity: 0;
    }
  }
}

@keyframes pulseButton {
  0% {
    box-shadow:none;
  }
  40% {
    box-shadow:0 0 0 10px rgba($color: #fdcb3d, $alpha: .5);
  }
  to {
    box-shadow:0 0 0 15px rgba($color: #fdcb3d, $alpha: 0);
  }
}