.work {

  &__tabs {
    gap: 10px;
    flex-direction: column;

    @include media-down-xl {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &__tab {
    border-radius: 16px;
    background: $light-blue;
    padding: 10px 16px 10px 10px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;

    @include media-down-xxl {
      font-size: 14px;
    }

    @include media-down-xl {
      width: calc(50% - 5px);
      flex: 0 0 auto;
    }

    @include media-down-sm {
      width: 100%;
    }

    &.active {
      background: $brand-blue;
      color: #ffffff;
    }
  }

  &__icon {
    width: 66px;
    height: auto;
    flex: 0 0 auto;
    margin-top: -6px;
    position: relative;

    @include media-down-md {
      width: 50px;
    }
  }

  &__slider {
    position: relative;

    &:after {
      content: "";
      z-index: 0;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    }
  }

  &__slider-item {
    overflow: hidden;
    border-radius: 20px;
  }

  &__img {
    width: 100%;
  }
}