.voice {
  background: $bg-yellow;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 1400px;
    height: 100%;
    background: url("../images/bg-yellow.svg") center left no-repeat;
    background-size: 52% auto;

    @include media-down-lg {
      width: 100%;
      height: 400px;
      background-size: auto 100%;
      transform: translate3d(-50%, -30px, 0);
    }

    @include media-down-sm {
      background-position: center center;
    }
  }

  &__wrap {
    position: relative;
    z-index: 1;
  }

  &__video-wrap {
    width: 100%;
    overflow: hidden;
    border-radius: 30px;
    display: flex;
    position: relative;

    @include media-down-lg {
      width: 500px;
    }

    @include media-down-sm {
      width: auto;
    }

  }

  &__video {
    width: 100%;
  }

  &__txt {
    font-size: 20px;
    font-weight: 500;

    @include media-down-xxl {
      font-size: 16px;
    }
  }
}