.ul {
  &--theme-hearts {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      margin: 0;

      &:not(:last-child) {
        padding: 0 0 16px;

        @include media-down-lg {
          padding-bottom: 8px;
        }
      }

      &:before {
        content: '';
        background: url('../images/icon-heart.svg') center center no-repeat;
        background-size: 100% auto;
        display: inline-block;
        width: .5em;
        height: .5em;
        margin-right: 8px;
        top: -2px;
        position: relative;
      }
    }
  }
}