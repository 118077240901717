.btn {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 30px;
  height: 42px;
  padding-bottom: 1px;
  padding-left: 22px;
  padding-right: 22px;
  color: $brand-blue;

  /*padding: 16px 24px 18px;*/
  /*min-width: 190px;*/
  font-weight: 600;
  border: 1px solid var(--borderColor, white);

  /*box-shadow: 0px 4px 5px 0px rgba($black-color, 0.25);*/
  transition: all .3s ease-in-out;

  @include media-down-md {
    padding: 10px 20px 12px;
    min-width: 150px;
  }

  &:hover {
    @media (hover: hover) {
      transition: all .5s ease-in-out;
    }
  }

  &--with-icon {
    background: white;
    display: flex;
    gap: 10px;
    padding-right: 6px;

    svg {
      width: 36px;
      height: 36px;
      position: relative;
      top: 1px;
      transition: all .3s ease-in-out;
    }

    &:hover {
      svg {
        transform: rotate(180deg);
      }
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  /*&--sizeS {
    padding: 10px 20px 10px;
    min-width: auto;
  }

  &--default {
    color: white;
  }*/

  &--inverted {
    --borderColor: white;
  }

  &--orange {
    background: $brand-orange;

    &:hover {
      @media (hover: hover) {
        background: $brand-orange;
        box-shadow: 0px 1px 4px 0px rgba($black-color, 0.3);
      }
    }
  }

  &--outlined {
    color: $white-color;
    position: relative;


    &:hover {
      @media (hover: hover) {
        color: $dark-orange;
        box-shadow: 0px 1px 4px 0px rgba($black-color, 0.3);
      }

      &:after {
        @media (hover: hover) {
          border: 3px solid $dark-orange;
          transition: all .5s ease-in-out;
        }
      }
    }
  }

  &--close {
    border: 0;
    position: relative;
    outline: none;
    cursor: pointer;
    background: url("../images/icon-close.svg") center center no-repeat transparent;
    background-size: 60% auto;
    width: 36px;
    height: 36px;
    opacity: .6;
    transform: rotate(0);
    transform-origin: center center;
    transition: all .3s ease-in-out;


    &:hover {
      @media (hover: hover) {
        opacity: 1;
        transform: rotate(180deg);
        transition: all .3s ease-in-out;
      }
    }

  }

  &--blue-shadow {
    box-shadow: 2px 2px 15px rgba($brand-blue, 0.25);

    &:hover {
      @media (hover: hover) {
        box-shadow: 2px 2px 18px rgba($brand-blue, 0.5);
      }
    }
  }

  &--orange-shadow {
    box-shadow: 2px 2px 15px rgba($brand-orange, 0.25);

    &:hover {
      @media (hover: hover) {
        box-shadow: 2px 2px 18px rgba($brand-orange, 0.5);
      }
    }
  }

/*  &--scroll {
    background: $darker-blue;
    border-radius: 50%;
    display: flex;
    align-items: center;
    width: 56px;
    height: 56px;
    overflow: hidden;

    @include media-down-sm {
      width: 40px;
      height: 40px;
    }

    svg,
    img {
      height: 18px;
      width: auto;
      top: 50%;
      left: 50%;
      margin-left: -7px;
      position: absolute;
      transform: translateY(-50%);
      animation: btnScroll 1.3s linear infinite;
    }

    &:hover {
      svg,
      img {
        animation: none;
      }
    }
  }

  @keyframes btnScroll {
    from {transform: translateY(-100%)}
    to {transform: translateY(-30%)}
  }*/
}



