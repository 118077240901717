.design {
  background: url("../images/video-bg.svg") center center no-repeat $bg-yellow;
  background-size: 80% auto;
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("../images/bg-design.svg") center center no-repeat;
    
    @include media-down-lg {
      display: none;
    }
  }

  & > div {
    position: relative;
    z-index: 1;
  }

  &__video,
  &__slider {
    overflow: hidden;
    border-radius: 30px;
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__slider {
    box-shadow: 2px 2px 30px rgba(202, 81, 33, 0.8);
  }

  &__slider-wrap {
    position: relative;
  }

}