.editor {
  background: $light-blue;
  border-radius: 30px;
  position: relative;
  min-height: 550px;
  font-size: 20px;
  font-weight: 500;

  &:after {
    content: "";
    position: absolute;
    width: 55%;
    height: 100%;
    right: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background: url("../images/bg-blue.svg") top left no-repeat;
    background-size: 120% auto;

    @include media-down-xl {
      width: 50%;
      height: 80%;
      top: 0;
      background-size: auto 100%;
    }

    @include media-down-lg {
      bottom: 0;
      top: auto;
      left: 0;
      width: 100%;
      height: 500px;
      background-position: center top;
      background-size: 100% auto;
    }

    @include media-down-md {
      height: 300px;
    }
  }

  @include media-down-xxl {
    font-size: 16px;
  }

  &__col-pic {
    position: absolute;
    right: -15px;
    top: 20px;
    height: 100%;
    z-index: 1;

    @include media-down-lg {
      right: 0;
      position: relative;
    }
  }

  &__txt {
    position: relative;
    z-index: 1;
  }

  &__img {
    width: 100%;
    height: auto;

    @include media-down-lg {
      width: 80%;
      max-width: 600px;
      margin-left: auto;
    }

    @include media-down-sm {
      width: 100%;
    }
  }
}