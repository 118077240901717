.h1 {
  font-size: 42px;
  margin-top: 0;
  margin-bottom: 0;

  @include media-down-xl {
    font-size: 38px;
  }

  @include media-down-md {
    font-size: 32px;
    line-height: 1.2;
  }

  @include media-down-sm {
    font-size: 28px;
  }
}

.h2 {
  color: var(--hColor, $brand-blue);
  font-size: 35px;
  font-weight: 700;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 0;

  @include media-down-xxl {
    font-size: 32px;
  }

/*  @include media-down-xxl {
    font-size: 44px;
  }

  @include media-down-lg {
    font-size: 36px;
  }*/
  @include media-down-xl {
    font-size: 30px;
  }

  @include media-down-md {
    font-size: 26px;
    line-height: 1.2;
  }

  @include media-down-sm {
    font-size: 22px;
  }

  &.title--orange {
    --hColor: #F48120;
  }
}

/*.h3 {
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  color: $black-color;

  @include media-down-md {
    font-size: 20px;
  }
}*/

.title {
/*  max-width: 970px;*/

  &--sm {
    font-size: .8em;
    color: #333333;
    display: block;
  }
}

.text--md {
  font-size: 24px;

  @include media-down-xl {
    font-size: 20px;
  }

  @include media-down-md {
    font-size: 18px;
  }

  @include media-down-sm {
    font-size: 16px;
  }

/*  @include media-down-lg {
    font-size: 18px;
  }*/
}

.text--bigger {
  font-size: 1.3em;
  line-height: 1.3;
  font-weight: 500;

  @include media-down-lg {
    font-size: 20px;
  }

  @include media-down-md {
    font-size: 18px;
  }
}

.color-primary {
  color: $brand-blue;
}

.text--bolder {
  font-weight: 600;
}