.garant {
  background: $bg-yellow;
  border-radius: 30px;
  padding: 0 32px;
  position: relative;
  overflow: hidden;
  font-size: 18px;

  @include media-down-xl {
    font-size: 16px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    width: 30%;
    height: 100%;
    background: url("../images/statue.png") top center no-repeat;
    background-size: 90% auto;

    @include media-down-md {
      width: 100%;
      height: 150px;
      left: 0;
      top: 20px;
      background: url("../images/law.png") top center no-repeat;
      background-size: auto 100%;
    }

  }

  &:after {
    content: "";
    position: absolute;
    top: -30px;
    left: 30px;
    width: 370px;
    height: 370px;
    background: rgb(255,209,108);
    background: linear-gradient(90deg, rgba(255,209,108,0) 0%, rgba(255,209,108,1) 100%);
    border-radius: 50%;
    opacity: .5;

    @include media-down-xxl {
      left: -30px;
    }

    @include media-down-xl {
      width: 320px;
      height: 320px;
      left: -60px;
      top: -80px;
    }

    @include media-down-md {
      left: 50%;
      margin-left: -160px;
      top: -100px;
    }

  }

  &__txt {
    padding-top: 32px;
    padding-bottom: 32px;
    position: relative;
    z-index: 2;

    @include media-down-md {
      padding-top: 170px;
    }
  }

  &__hidden {
    display: none;

    li {
      margin-bottom: 12px;
      font-size: .8em;
    }
  }

  &__pic {
    max-width: 320px;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 2;

    @include media-down-md {
      max-width: 200px;
    }
  }
}