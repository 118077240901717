.link--decoration-none {
  text-decoration: none;
}

/* old */

.link--orange {
  color: $brand-orange;
  transition: all .3s ease-in-out;

  &:hover {
    @media (hover: hover) {
      color: $dark-orange;
      transition: all .3s ease-in-out;
    }
  }
}

.link--black {
  color: #333333;
  text-decoration: none;
  transition: all .3s ease-in-out;

  &:hover {
    @media (hover: hover) {
      color: #666666;
      transition: all .3s ease-in-out;
    }
  }
}

.link--manual {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: .5em;

  &:before {
    content: "";
    display: flex;
    position: relative;
    width: 1.1em;
    height: 1.1em;
    top: 0.05em;
    background: url("../images/icon-document.svg") center center no-repeat;
    background-size: auto 100%;
    transform-origin: center center;
    transform: scale(1);
    transition: .1s all ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      &:before {
        transform: scale(1.1);
        transition: .1s all ease-in-out;
      }
    }
  }
}

.link--reg {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: .5em;

  &:before {
    content: "";
    display: flex;
    position: relative;
    width: 1.1em;
    height: 1.1em;
    background: url("../images/icon-person.svg") center center no-repeat;
    background-size: auto 100%;
    transform-origin: center center;
    transform: scale(1);
    transition: .1s all ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      &:before {
        transform: scale(1.1) translateY(1px);
        transition: .1s all ease-in-out;
      }
    }
  }
}

.link--dashed {
  text-decoration: none;
  border-bottom: 1px dashed;

}