.projects {
  font-size: 15px;
  font-weight: 500;

  &__block {
    border: 2px solid #B7D0F4;
    padding: 16px;
    border-radius: 30px;
  }

  &__block-header {
    gap: 16px;
  }

  &__block-title {
    font-weight: 700;
    font-size: 18px;
  }

  &__block-h-icon {
    width: 70px;
    display: flex;
    flex: 0 0 auto;

    img {
      width: 100%;
    }

  }

  &__row-bordered {
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background: #B7D0F4;
      opacity: .6;
      left: 16px;
      right: 16px;
      top: 0;
    }
  }

  &__link--with-icon {
    img {
      width: 20px;
      height: 20px;
      display: flex;
      flex: 0 0 auto;
      margin-right: 10px;
    }
  }

  &__link--tech {
    position: relative;
    margin-left: 24px;

    &:before {
      content: "";
      position: absolute;
      left: -24px;
      top: 3px;
      width: 16px;
      height: 16px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' style='enable-background:new 0 0 49.3 54.8' viewBox='0 0 49.3 54.8'%3E%3Cpath d='M24.5 54.8c-.3 0-.6-.1-.8-.2L.8 41c-.5-.3-.8-.9-.8-1.4V14.3c0-.6.3-1.2.9-1.4L24.3.2c.5-.3 1.1-.3 1.5 0l22.6 11.7c.5.3.9.8.9 1.5V40c0 .6-.3 1.1-.8 1.4L25.3 54.6c-.3.2-.6.2-.8.2zM3.3 38.6l21.2 12.7L46 39V14.3L25.1 3.5 3.3 15.3v23.3z'/%3E%3Cpath d='M24.6 29.3c-.3 0-.6-.1-.8-.2L2 16.2c-.8-.5-1-1.5-.6-2.2.5-.8 1.5-1 2.2-.6l21 12.4 21.7-13c.8-.5 1.8-.2 2.3.6.5.8.2 1.8-.6 2.3L25.4 29.1c-.2.2-.5.2-.8.2z'/%3E%3Cpath d='M24.5 53.3c-.9 0-1.6-.7-1.6-1.6V27.4c0-.9.7-1.6 1.6-1.6s1.6.7 1.6 1.6v24.2c0 1-.7 1.7-1.6 1.7z'/%3E%3C/svg%3E") center center no-repeat;
      background-size: contain;
    }
  }

  &__link--site {
    position: relative;
    margin-left: 24px;

    &:before {
      content: "";
      position: absolute;
      left: -24px;
      top: 3px;
      width: 14px;
      height: 14px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' id='Layer_1' x='0' y='0' style='enable-background:new 0 0 360 360.1' version='1.1' viewBox='0 0 360 360.1'%3E%3Cstyle%3E.st0%7Bfill:%23333%7D%3C/style%3E%3Cpath d='M180 360C80.7 360 0 279.3 0 180S80.7 0 180 0s180 80.7 180 180-80.7 180-180 180zm0-340C91.8 20 20 91.8 20 180s71.8 160 160 160 160-71.8 160-160S268.2 20 180 20z' class='st0'/%3E%3Cpath d='M166 360.1c-3.1-1.4-75.9-35.3-77.9-180-.7-41.5 5.6-100.1 39.2-143.7C148.1 9.5 169.5 1 170.4.7l7.2 18.7L174 10l3.6 9.3c-.2.1-18 7.4-35.3 30.4-23.2 30.9-35.1 75.9-34.3 130.1.5 37.9 6.9 91.5 34 131.1 16 23.3 32 30.9 32.1 31l-8.1 18.2zM197 360.1l-8.1-18.3c.1 0 16-7.6 32-30.9 27.1-39.6 33.5-93.1 34-131.1.8-54.3-11.1-99.3-34.3-130.1-17.3-23.1-35.2-30.4-35.3-30.4L192.5.7c.9.3 22.4 8.8 43.1 35.7 18.5 24.1 40.4 68.5 39.3 143.7-2 144.8-74.8 178.7-77.9 180z' class='st0'/%3E%3Cpath d='M23 109h316v20H23zM23 232h316v20H23z' class='st0'/%3E%3C/svg%3E") center center no-repeat;
      background-size: contain;
    }
  }

  &__link--logo {
    background: $light-blue;
    border-radius: 10px;
    height: 56px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;

    img {
      height: 100%;
      width: auto;
    }
  }

  &__link--logo-wide {
    flex: 1;
  }

  a {
    color: #000000;
    text-decoration: none;
    transition: all .3s ease-in-out;

    &:hover {
      @media (hover: hover) {
        color: $brand-orange;
        transition: all .3s ease-in-out;
      }
    }
  }

}