.intro {
  &__subtitle {
    font-size: 24px;
  }

  &__stat {
    color: $brand-orange;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    max-width: 400px;
  }

  &__stat-number {
    font-size: 40px;
    line-height: 1;
    white-space: nowrap;
  }

  &__stat-sm {
    line-height: 1;
  }

  &__adv-title {
    background: $brand-orange;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding-bottom: 36px;
    margin-bottom: -30px;

    .h2 {
      --hColor: #ffffff;
    }
  }

}