.eye {
  background: $light-blue;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 500;

  @include media-down-xxl {
    font-size: 16px;
  }

  &__img-wrap {
    display: flex;
    border-radius: 30px;
    overflow: hidden;
    transform: translateY(-24px);
    margin-left: 24px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);

    @include media-down-lg {
      width: 500px;
    }

    @include media-down-sm {
      width: auto;
      margin: 16px;
      transform: translateY(0);
    }
  }
}