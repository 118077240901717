// colors

$darker-blue:   #509FCC;
$bright-blue:   #1AA5C9;


$dark-orange:   #e15f06;
$text-color:    #333333;
$black-color:   #000000;


$white-color: #ffffff;
$alice-blue: #f8f9fa;
$white-smoke: #f5f5f5;
$white-smoke2: #f0f0f0;
$white-smoke2: #f0f0f0;

// NEW
$dark-blue:     #08377D;
$brand-blue:    #527DD4;
$light-blue:    #D5F3FF;
$brand-orange:  #F48120;
$bg-yellow: #FFECC6;




// bootstrap

$grid-gutter-width: 32px;

$container-max-widths: (
        sm: 420px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
);


$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
) !default;



// media
@mixin media-down-xxl {
  @media (max-width: 1439px) {
    @content;
  }
}

@mixin media-down-xl {
  @media (max-width: 1199px) {
    @content;
  }
}

@mixin media-down-lg {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin media-down-md {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin media-down-sm {
  @media (max-width: 575px) {
    @content;
  }
}

