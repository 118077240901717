.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 48px;
  height: 48px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;

  @include media-down-lg {
    width: 40px;
    height: 40px;
  }

  @include media-down-sm {
    width: 32px;
    height: 32px;
  }
}

.slick-prev,
.slick-next {
  &:before {
    content: "";
    display: block;
    cursor: pointer;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    transform-origin: center center;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Ccircle cx='24' cy='24' r='22.5' transform='matrix(-1 0 0 1 48 0)' stroke='%23FF7A2F' stroke-width='3'/%3E%3Cpath d='M29.6766 38.5303C29.8825 38.2172 29.9901 37.852 29.9994 37.4345C30.0088 37.017 29.9012 36.6517 29.6766 36.3386L20.835 24.0102L29.6766 11.6817C29.8825 11.3947 29.9901 11.036 29.9994 10.6054C30.0088 10.1749 29.9012 9.80311 29.6766 9.49001C29.4708 9.1769 29.2135 9.01383 28.9048 9.00078C28.596 8.98774 28.3293 9.13777 28.1048 9.45087L18.2526 23.1883C18.1591 23.3187 18.0936 23.4492 18.0561 23.5797C18.0187 23.7101 18 23.8536 18 24.0102C18 24.1667 18.0187 24.3102 18.0561 24.4407C18.0936 24.5712 18.1591 24.7016 18.2526 24.8321L28.1048 38.5695C28.3106 38.8565 28.5679 39 28.8767 39C29.1854 39 29.4521 38.8434 29.6766 38.5303Z' fill='%23FF7A2F'/%3E%3C/svg%3E") center center no-repeat;
    background-size: 100% auto;

    @include media-down-lg {
      width: 40px;
      height: 40px;
    }

    @include media-down-sm {
      width: 32px;
      height: 32px;
    }
  }
}

.slick-prev {
  &:before {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
    transition: .2s all ease-in-out;
  }

  &:hover {
    @media (hover: hover) {
      &:before {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.3);
        transition: .2s all ease-in-out;
      }
    }
  }
}

  .slick-next {
    &:before {
      transform: rotate(180deg);
      box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.25);
      transition: .2s all ease-in-out;
    }

    &:hover {
      @media (hover: hover) {
        &:before {
          box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.3);
          transition: .2s all ease-in-out;
        }
      }
    }
  }

.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 16px;
      height: 16px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: none;
      background: transparent;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        content: '';
        text-align: center;
        background: url("../images/heart-grey.svg") center center no-repeat;
        background-size: 8px 8px;
        transition: all .3s ease-in-out;
      }

      &:hover {
        @media (hover: hover) {
          &:before {
            background-size: 16px 16px;
            transition: all .3s ease-in-out;
          }
        }
      }
     }

    &.slick-active {
      button {
        &:before {
          background: url("../images/icon-heart.svg") center center no-repeat;
          background-size: 100% auto;
        }
      }
    }
  }
}


.slick-track {
  display: flex !important;
}

.slick-slide {
  height: auto;
}

.slick-slide > div {
  height: 100%;
}




