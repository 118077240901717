@media (prefers-color-scheme: dark) {
  .intro__round {
    background: url("../images/round.png") center center no-repeat!important;
    background-size: 100% auto!important;

    &:after {
      display: none!important;
    }

    .intro__round-txt,
    .intro__round-icon {
      display: none!important;
    }
  }
}
