.ul--check {
  margin: 0;
  padding: 0;

  li {
    margin: 0 0 12px;
    padding: 0 0 0 36px;
    list-style-type: none;
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      left: 0;
      width: 24px;
      height: 24px;
      background: url("../images/icon-ul-check.svg") center center no-repeat;
      background-size: 100% auto;

      @include media-down-xxl {
        top: -1px;
      }
    }
  }
}