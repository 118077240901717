.sertif {
  background: $light-blue;

  &__row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: -16px;
    margin-left: -16px;
    margin-right: -16px;

    @include media-down-xxl {
      margin-top: -10px;
      margin-left: -10px;
      margin-right: -10px;
    }
  }

  &__hidden {
    display: none;
  }

  &__item {
    width: 20%;
    flex: 0 0 auto;
    padding: 16px;

    @include media-down-xxl {
      padding: 10px;
    }

    @include media-down-xl {
      width: 25%;
    }

    @include media-down-lg {
      width: 33%;
    }

    @include media-down-md {
      width: 50%;
    }

    @include media-down-sm {
      width: 100%;
    }

  }

  &__link {
    padding: 16px;
    background: white;
    border-radius: 24px;
    box-shadow: 0px 0px 16px #aec8fa;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    transition: box-shadow, transform ease-in-out .3s;

    &:hover {
      @media (hover: hover) {
        box-shadow: 4px 4px 20px $brand-blue;
        transform: scale(1.05) translate3d(0, 0, 0);
        -webkit-transform: scale(1.05) translate3d(0, 0, 0);
      }
    }
  }

  &__pic {
    height: 250px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__txt {
    font-size: 13px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    font-weight: 500;
  }

  &__modal {
    .modal-content {
      border-radius: 25px;
    }

    .btn--close {
      margin-top: -5px;
      margin-right: -5px;
    }

    .modal-body {
      text-align: center;

      img {
        max-width: 100%;
      }
    }
  }

  &__show-all-wrap {
    padding-top: 30px;
    background: rgb(213,243,255);
    background: linear-gradient(180deg, rgba(213,243,255,0) 0%, rgba(213,243,255,1) 100%);
  }
}

